.Table__itemCount {
    /* margin-top: 10px; */
    font-size: 14px;
  }
  
  .Table__pagination {
    display: flex;
    justify-content: flex-end;
    /*padding: 20px 10px;*/
  }
  
  .Table__pageButton {
    font-size: 15px;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
  
  .Table__pageButton:disabled {
    cursor: not-allowed;
    color: gray;
  }
  
  .Table__pageButton--active {
    color: #45b3e3;
    font-weight: bold;
  }
  
  .ReactTable .rt-thead.-header {
      box-shadow: none;
      background: #FFFFFF;
      height: 40px;
      border: 1px solid #F0EFF1;
      padding-top: 8px;
      padding-left: 8px;
      font-size: 14px;
      font-weight: 600;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
  }

.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
      border: 0px;
      color: #8E8E8E;
}

.rt-tbody {
      min-width: 300px;
      border: 1px solid #F0EFF1;
      border-top: 0px;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
}

.ReactTable .rt-thead.-filters {
      border:  1px solid #F0EFF1;
      border-top: 0px;
      border-bottom: 0px;
}

.rt-td {
      padding-left: 12px ! important;
      color: #373737;
      font-weight: 700;
}

.ReactTable .rt-tbody .rt-tr-group {
      border-bottom: 0px;
}

.rt-tr {
      padding: 8px 0px 0px 0px;
}