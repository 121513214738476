.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.react-date-picker__wrapper {
  border: none ! important;
  color: #515151 ! important;
}

.react-date-picker__inputGroup {
  color: #b7b7b7 ! important;
}
.react-date-picker__inputGroup__input {
  color: #b7b7b7 ! important;
}

.modal-size {
  position: absolute;
  top: 5px;
  left: 50%;
  font-size: 16px;
  z-index: 150000;
  width: 800px;
  margin-left: -400px;
  height: 95vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 1px;
}

.modal-content {
  margin-top: 5px;
  width: inherit;
}
